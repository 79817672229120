<div>
  <span>Sender:  </span><span><b>{{sender}}</b></span>
</div>

<div>
  <span>{{recipientLabel}}</span><span><b>{{recipients}}</b></span>
</div>

<div>
  <span>Badge: </span>
  {{#if isDenyForm}}
    <span><img src="{{badgeImage}}" height="21px"></span>
  {{/if}}
  <span>{{badgeName}}</span>
</div>

{{#if diffPoints }}
  <div>
    <span>Min Badge Value: </span><span>{{minPoints}}</span>
  </div>
  <div>
    <span>Sender Requested: </span><span>{{pointsGivenBySender}}</span>
  </div>
{{else}}
  <div><span>Points: </span><span>{{minPoints}}</span></div>
{{/if}}

<div class="mt-2">
  {{#if pointsDeducted}}
    <label class="text-start text-warning mb-1">
      Sender has less points to give than the requested amount. Please choose a point within the available range.
    </label>
    <br>
  {{/if}}

  <label class="d-inline">
    {{#unless diffPoints}}
      Recognition Points:
    {{/unless}}
    <b><span id="slider-label">{{maxPoints}}</span>pts</b>
  </label>
  <br>

  {{#if diffPoints}}
    <input type="range" class="w-50" id="points-slider" min="{{minPoints}}" max="{{maxPoints}}"
      name="points_by_approver" step="1" value="{{maxPoints}}">
    <div>
      <label class="me-5">{{minPoints}}</label>
      <label class="ms-5">{{maxPoints}}</label>
    </div>
  {{/if}}
</div>

<br>
<br>
<a href={{link}} target="_blank"
   style="font-family: outfit, helvetica, arial, sans-serif; display: block; margin: 20px auto; padding: 50px 10px; text-align: center; text-decoration: none;">
    <img src="{{badge.url}}" alt="{{badge.title}}" style="margin-bottom: 5px;">
    <h3 style="margin-bottom: 15px; color: black;">{{badge.title}}</h3>
    <hr>
    <p style="color: black;">{{message}}</p>
</a>
<br>
<br>
